body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: visible;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New,
    monospace;
}

.link {
  color: #4848e9;
  text-decoration: none;
}

/* Scroll */
.scroll-up-container {
  position: fixed;
  background-color: rgba(4, 5, 47, .75) !important;
  border: none !important;
  border-radius: 5px !important;
  color: #ffffff !important;
  margin-bottom: 70px !important;
  font-size: 10px !important;
  width: 30px !important;
  height: 30px !important;
  right: 10px !important;
  z-index: 9998 !important;
}

/* RESUME */
.resume-border-closed {
  border-radius: 10px;
}

.resume-border-open {
  border-radius: 10px 10px 0 0;
}

.resume-content {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.resume-content-info {
  border-radius: 0 0 10px 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

/* FOOTER */
.footer-background {
  position: relative;
  contain: content;
  margin-top: 2rem;
  height: 5rem;
  left: 0;
  width: 100%;
  text-align: center;
  /*background-color: rgb(4;5;47);
  color: #fff;*/
}

.footer-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #04052F;
  z-index: 1;
}

/* HEADER */
.header-background {
  background-image: url(./img/test.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: flex;
  contain: content;
  height: 10rem;
}

.header-info-container {
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: rgba(4, 5, 47, .75);
}

.header-img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  contain: content;
}
.header-text {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

/* Content */
.body-container {
  padding: 0;
  margin: 0;
  position: absolute;
  contain: content;
  top: 12rem;
  min-height: calc(100% - 12rem);
  height: fit-content;
  margin-bottom: 5rem;
  left: 0;
  right: 0;
  /*border: 1px solid #eaeaea;*/
}
.content-container {
  contain: content;
  border: solid 1px #eaeaea;
  border-radius: 10px;
  box-shadow: 0 0 10px #5d5a5e71;
}
.content-info {
  margin: 0;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

@media screen and (min-width: 1200px) {
  /* HEADER */
  .header-img {
    margin-left: 50px;
    width: 140px;
  }
  .header-text {
    margin-right: 50px;
    width: calc(100% - 140px - 115px);
  }
  .education-content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 625px) and (max-width: 1200px) {
  /* HEADER */
  .header-img {
    margin-left: 50px;
    width: 140px;
  }
  .header-text {
    margin-right: 50px;
    width: calc(100% - 140px - 115px);
  }
}

@media screen and (min-width: 700px) {
  /*Progress*/
  .progress-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    top: 50%;
    justify-self: center;
    transform: translateY(-50%);
    left: 10px;
    width: 100px;
  }

  .progress-item {
    height: 60px;
    width: 100px;
    contain: content;
  }

  .progress-item-front {
    margin-bottom: 10px;
  }

  /*Content*/
  .content-container {
    margin-left: 50px;
    margin-right: 50px;
  }
  .content-info {
    margin-left: 120px;
    padding-left: 10px;
    border-left: solid 1px #eaeaea;
  }
}

@media screen and (max-width: 700px) {
  /*Progress*/
  .progress-container {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: center;
    contain: content;
    left: 10px;
    right: 10px;
    top: 10px;
    height: auto;
  }

  .progress-item {
    height: 50px;
    width: 80px;
    contain: content;
  }

  .progress-item-front {
    margin-right: 10px;
  }
  
  .content-info {
    margin-left: 10px;
    margin-top: 75px;
    padding-top: 10px;
    border-top: solid 1px #eaeaea;
  }

  /*Content*/
  .content-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 625px) {
  /* HEADER */
  .header-info-container {
    position: absolute;
    height: 100%;
    width: 100%;
  }  
  .header-img {
    margin-left: 50px;
    width: 140px;
  }
  .header-text {
    margin-right: 50px;
    width: calc(100% - 140px - 115px);
  }
}

@media screen and (max-width: 1200px) {
  /* HEADER */
  .header-info-container {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .education-content {
    flex-direction: column;
  }
}